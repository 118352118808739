$very-light-grey: rgba(240, 240, 240, 1);
$mild-orange: #FAC043;
$admin-green: #30D417;
$bold-orange: #ff8402;
$mid-grey: #777;
$phone: 400px;
$tablet: 600px;
$desktop: 1024px;
.basket-bar-mobile {
    position: fixed;
    height: 50px;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $mild-orange;
    z-index: 4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: $tablet) {
        display: none;
    }
}

#productModal {
    img {
        width: 300px;
        height: 300px;
        @media only screen and (max-width: $tablet) {
            width: 40vw;
            height: 40vw;
            font-size: 0.9rem;
        }
    }
    .row {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: $tablet) {
            margin-bottom: 5px;
        }
    }
}

.mobile-menu-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 19;
}

#menu-icon-holder {
    color: $bold-orange;
    @media only screen and (min-width: $tablet) {
        display: none;
    }
}

.language-bit-main {
    @media only screen and (max-width: $tablet) {
        display: none;
    }
}

@keyframes fade {
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@keyframes inFromRight {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

.mobile-menu {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    bottom: 0;
    width: 260px;
    transform: translateX(-100%);
    background-color: $bold-orange;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    a {
        text-decoration: none !important;
        color: white !important;
    }
    &.open {
        animation-name: inFromRight;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;
    }
    &.closed {
        animation-name: inFromRight;
        animation-duration: 0.8s;
        animation-fill-mode: backwards;
    }
    .option {
        cursor: pointer;
        color: white;
        padding: 10px 20px;
        font-size: 1.2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}

.App {
    text-align: center;
    width: 100vw;
}

.screen {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#search-filter {
    width: 170px;
    @media screen and (min-width: $desktop) {
        width: 218px;
    }
}

.product {
    width: 180px;
    display: flex;
    position: relative;
    border-radius: 4px;
    flex-direction: column;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 0;
    margin-bottom: 20px;
    @media screen and (max-width: 800px) {
        width: 160px;
    }
    .offers-list {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 13;
        background-color: black;
        border-radius: 3px;
        color: white;
        display: flex;
        flex-direction: column;
        padding: 3px;
        overflow-y: auto;
        .row {
            display: flex;
            flex-direction: column;
            align-self: stretch;
            font-size: 0.8rem;
            cursor: pointer;
            margin-bottom: 5px;
            .top {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
            &.selected {
                font-weight: bold;
            }
            &:hover {
                background-color: grey;
            }
        }
    }
    img {
        width: 180px;
        height: 180px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        @media screen and (max-width: $phone) {
            width: 140px;
            height: 140px;
        }
        @media screen and (max-width: 800px) {
            width: 160px;
            height: 160px;
        }
    }
    .ean {
        font-size: 0.75em;
        opacity: .7;
        color: $mid-grey;
        text-transform: uppercase;
        margin-bottom: 0.1em;
        margin-top: 0.1em;
        cursor: default;
    }
    .description {
        font-size: 0.9em;
        opacity: 0.75;
        margin-top: 0.1em;
        margin-bottom: 0.1em;
        cursor: default;
        &:hover {
            opacity: 1;
        }
    }
    .price {
        font-size: 0.8em;
        font-weight: bold;
        margin-top: 0.1em;
        margin-bottom: 0.1em;
        cursor: default
    }
    .price.unit {
        font-weight: 400;
    }
}

.grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 180px 180px 180px 180px;
    gap: 0.3em;
    align-self: center;
    @media only screen and (max-width: 800px) {
        grid-template-columns: 160px 160px;
        gap: 20px;
    }
    @media only screen and (min-width: 801px) and (max-width: $desktop) {
        grid-template-columns: 180px 180px 180px;
    }
    &.homepage {
        grid-template-columns: 160px 160px 160px 160px 160px;
        gap: 20px;
        @media only screen and (max-width: $desktop) {
            grid-template-columns: 180px 180px 180px 180px;
            gap: 20px;
        }
        @media only screen and (min-width: 601px) and (max-width: 800px) {
            grid-template-columns: 180px 180px 180px;
            gap: 20px;
            .product {
                display: none;
                &:nth-child(n+2) {
                    display: flex;
                }
            }
            @media only screen and (max-width: 600px) {
                grid-template-columns: 160px 160px;
                gap: 20px;
            }
        }
    }
}

.mobile-search {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    background-color: $bold-orange;
    display: flex;
    flex-direction: row;
    padding: 3px;
    justify-content: center;
    align-items: center;
    z-index: 5;
    @media only screen and (min-width: $tablet) {
        display: none;
    }
    .holder {
        flex-direction: row;
        display: flex;
        border-radius: 20px;
        overflow: hidden;
        background-color: lightgray;
        .top-search-input {
            margin-left: 20px;
        }
    }
}

.home-page-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    h2 {
        font-size: 18px;
        margin-left: 10px;
        @media only screen and (min-width: $desktop) {
            font-size: auto;
            margin-left: auto;
        }
    }
}

.search_and_login {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: $tablet) {
        flex-direction: column;
        .divide {
            display: none;
        }
    }
}

.footer-list {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media only screen and (max-width: $tablet) {
        flex-direction: column;
        font-size: 0.9em;
        .divide {
            display: none;
        }
    }
}

.hide-mobile {
    @media only screen and (max-width: $tablet) {
        display: none;
    }
}

.centered {
    align-self: stretch;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
}

.right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.3em;
}

.button {
    border: solid 1.5px $mild-orange;
    border-radius: 2px;
    padding: 0.1em 0.7em;
    color: $mild-orange;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.button:hover {
    background-color: $mild-orange;
    color: white;
}

.button.large {
    padding: 0.2em 0.9em;
}

.button.text {
    border: none;
    font-size: 0.9em;
}

.button.cancel {
    color: grey;
    border: solid 1.5px grey;
}

.button.warn {
    color: red;
    border: solid 1.5px red;
}

.button.warn:hover {
    background-color: red;
    color: white;
}

.button.cancel:hover {
    background-color: grey;
    color: white;
}

.space {
    flex: 1;
}

.between {
    display: flex;
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5em;
}

input {
    border: none;
    padding: 0.5em;
    font-size: 1.4em;
    background-color: lightgray;
    color: white;
}

#loginModal {
    display: flex;
    flex-direction: column;
}

#loginModal p {
    margin: 5px;
}

.loginSecondaryActions p {
    margin: 0.4em;
}

.loginSecondaryActions {
    margin-top: 1em;
    font-size: 0.8em;
}

.slashed {
    text-decoration: line-through;
    text-decoration-style: #333;
    color: #666;
    margin-right: 0.4em;
}

.promoted {
    color: red;
}

.basketButtonGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    justify-content: center;
}

.basketButtonGroup p {
    margin-right: 0.5em;
    margin-left: 0.5em;
    display: flex;
}

.basketButtonGroup .more,
.less {
    border-radius: 2px;
    height: 1.3em;
    width: 1.3em;
    border: solid 1px#066489;
    display: flex;
    align-items: center;
    justify-content: center;
}

.basketButtonGroup .more {
    color: white;
    background-color: #066489;
}

.basketButtonGroup .less {
    color: #066489;
    background-color: white;
    border: solid 1px#066489;
}

.pagination-bar {
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    padding: 0px 20px;
    @media only screen and (max-width: $phone) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
    }
    .areas {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
        padding: 20px 5px;
        align-items: center;
    }
    .pages {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media only screen and (max-width: $phone) {
            flex-direction: column;
        }
        .space_if_phone {
            @media only screen and (max-width: $phone) {
                height: 10px;
            }
        }
    }
}

.title-text {
    height: 60px;
    align-self: stretch;
    font-size: 30px;
    font-weight: bold;
    color: #666;
    justify-content: center;
    align-items: center;
    display: flex;
    @media only screen and (max-width: $tablet) {
        font-size: 1.3rem;
    }
}

.prom-holder {
    max-width: 1080px;
}

.about-us-holder {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 20px;
    .text {
        background-color: $very-light-grey;
        padding: 20px;
        font-size: 1rem;
    }
    .about-us-homepage {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
        }
        img {
            width: 400px;
            @media only screen and (max-width: 800px) {
                width: 100%;
            }
        }
    }
}

.brands-holder {
    max-width: 1080px;
}

@media only screen and (max-width: 800px) {
    .top-search-input {
        width: 150px;
    }
    .top-header {
        flex-direction: column !important;
        align-items: center !important;
    }
    .hide-mobile {
        display: none !important;
    }
}

.contact-header {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media only screen and (max-width: $tablet) {
        display: none;
    }
}

.top-spacer {
    width: 30vw;
}

.top-search-input {
    width: 200px;
}

.store-center {
    max-width: 100vw;
    overflow: hidden;
    @media only screen and (min-width: $desktop) {
        max-width: 1020px;
    }
    display: flex;
    flex-direction: column;
}

.store-filters {
    width: 200px;
    color: #555;
    margin-right: 10px;
    background-color: rgba(200, 200, 200, 0.2);
    display: none;
    font-size: 0.9rem;
    @media only screen and (min-width: $tablet) {
        display: block;
    }
    @media only screen and (min-width: $desktop) {
        width: 280px;
        font-size: 1rem;
    }
}

.hdiv {
    align-self: stretch;
    display: flex;
    border-top: solid 1px rgba(200, 200, 200, 1);
    margin: 10px;
}

.pageLink {
    color: #666;
    cursor: pointer;
    font-size: 1.2em;
    margin-right: 6px;
}

.pageLink.circle {
    border-radius: 100px;
    width: 24px;
    height: 24px;
    color: #666;
    border: solid 2px #666;
}

.cas-button {
    color: #666;
    cursor: pointer;
    font-size: 1.1em;
    height: 24px;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    background-color: $mild-orange;
    font-weight: bold;
    color: white;
    border-radius: 2px;
}

.cas-button:hover {
    background-color: $mild-orange;
}

.pageLink.active {
    background-color: $mild-orange;
    border: solid 2px $mild-orange;
    color: white;
    font-weight: bold;
    font-size: 1.2em;
}

.paginator {
    display: flex;
    flex-direction: row;
}

.resultsCount {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
    color: #777;
}

.header-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.top-header {
    background-color: white;
    display: flex;
    padding: 10px;
    padding-bottom: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.header-container .tag {
    display: flex;
    align-self: stretch;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $mild-orange;
    padding: 12px;
    @media only screen and (max-width: $tablet) {
        .span {
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 800px) {
    .top-header li.divide {
        display: none;
    }
}

.news-article {
    align-self: stretch;
    background-color: $very-light-grey;
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    position: relative;
    @media only screen and (max-width: $tablet) {
        flex-direction: column;
    }
}

.article-page {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: $tablet) {
        flex-direction: column;
    }
}

.top-header .address ul {
    padding: 5px;
    font-size: 0.8em;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-weight: bold;
    color: #444;
}

.main-header {
    height: 50px;
    background-color: $bold-orange;
    position: sticky;
    top: 0px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    @media only screen and (max-width: $desktop) {
        display: none;
    }
}

.split-header {
    background-color: $bold-orange;
    position: sticky;
    top: 0px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    width: 100vw;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    @media only screen and (min-width: $desktop) {
        display: none !important;
    }
    @media only screen and (max-width: $tablet) {
        display: none !important;
    }
}

nav ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.active-link {
    color: white;
}

nav ul li {
    color: rgb(70, 70, 70);
    font-size: 1.2em;
    display: block;
}

nav ul a:hover {
    color: white;
}

nav ul a {
    color: white;
}

a {
    text-decoration: none;
}

li.divide {
    border-left: 1px solid rgba(0, 0, 0, .2);
    height: 30px;
    vertical-align: middle;
    margin: 0 20px;
    position: relative;
}

.rep-header {
    height: 50px;
    background-color: $admin-green;
    position: sticky;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: $tablet) {
        display: none;
    }
}

.product-in-checkout img {
    width: 100px;
    height: 100px;
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation-duration: 500ms;
    animation-name: fade;
}

.fade-out {
    animation-duration: 500ms;
    animation-name: fade;
    animation-fill-mode: backwards;
}

.fave-heart {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

.hide {
    display: none !important;
}

.dropdown-menu {
    position: absolute;
    bottom: 0;
}

#manage-table {
    font-size: 0.8em;
    border-spacing: 0;
    border-collapse: collapse;
}

#manage-table th {
    white-space: nowrap;
}

#manage-table td.description {
    text-align: left;
}

#manage-table td {
    padding: 2px 5px;
}

#manage-table td input {
    width: 90%;
    padding: 0px;
    color: #666;
    font-size: 0.9em;
}

#manage-table td.listed {
    background-color: rgba(100, 200, 100, 0.5);
}

td.updated {
    background-color: rgba(200, 0, 0, 0.5) !important;
}

.selected-offer {
    background-color: rgba(0, 0, 200, 0.5);
    color: black;
}

.unselected-offer {
    background-color: lightgray;
}

#upload-catalogue {
    width: 100px;
}

#manage-search {
    border: solid 1px grey;
    background-color: white;
    height: 20px;
    padding: 2px;
    font-size: 14px;
    color: grey;
    width: 150px;
}

table.order-view td {
    padding-left: 10px;
    padding-right: 10px;
}

#search-top {
    padding: 5px;
    font-size: 14px;
}

.terms p {
    text-align: left;
}

.terms ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#search-filter {
    background-color: white;
    border-color: hsl(0, 0%, 70%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: hsl(0, 0%, 20%);
    font-size: 16px;
}

.list-view {
    display: flex;
    flex-direction: column;
}

.product-list {
    align-self: stretch;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    position: relative;
}

.product-list .product-img {
    width: 100px;
    height: 100px;
}